import { css, html, LitElement } from "lit"
import { NumberParser } from "#js/components/utils"

export class PercentageInput extends LitElement {
  static get properties() {
    return {
      value: { type: Number, reflect: true },
    }
  }

  constructor() {
    super()
    this.parser = new NumberParser(globalThis.language)
  }

  static styles = css`
    strong[contenteditable] {
      text-decoration: underline;
      text-decoration-color: var(--brand-color);
      text-decoration-thickness: 0.1em;
      text-underline-offset: 0.2em;
      text-decoration-style: dotted;
      cursor: pointer;
    }

    strong.error {
      text-decoration-color: var(--warning);
      text-decoration-style: wavy;
    }
  `

  render() {
    return html`<strong contenteditable="true" @input="${this.input}">${
      this.value.toLocaleString(globalThis.language, {
        style: "percent",
        maximumFractionDigits: 1,
      })
    }</strong>`
  }

  input(event) {
    const value = this.parser.parse(event.target.innerText)
    if (!isNaN(value)) {
      event.target.classList.remove("error")
      this.value = value / 100
      this.dispatchEvent(
        new CustomEvent("change", {
          detail: this.value,
        }),
      )
    } else {
      event.target.classList.add("error")
    }
  }
}

globalThis.customElements.define("percentage-input", PercentageInput)
